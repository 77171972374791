/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { hot } from 'react-hot-loader/root';
import { Checkbox } from '@mui/material';
import { CustomDialog, DialogHeader, DialogContent } from '../common/Dialog';
import constants from '../../constants';
import Carousel from '../common/carousel/Carousel';
import { sortByProp } from '../../utils';
import { getNewReleaseContent } from '../../services/getNewReleaseContent';
import { updateUserPrefereneceForViewingNewReleaseContent } from '../../services/updateUserPrefereneceForViewingNewReleaseContent';
import { getUserPrefereneceToViewNewReleaseContent } from '../../services/getUserPrefereneceToViewNewReleaseContent';
import './NewReleaseDialog.style.scss';

const NewReleaseDialog = props => {
  const {
    store: {
      Config: { RELEASE_NUMBER },
      UserInfo: { userId },
      Filters: {
        setShowNewReleaseContent,
        showNewReleaseContent,
        updateCookieForNewReleaseContent
      }
    },
    previewImages,
    closeNewReleaseDialog
  } = props;
  const { NEW_RELEASE_DIALOG_TITLE, NEW_RELEASE_DIALOG_FOOTER_MSG } = constants;
  const [doNotShowMessage, setDoNotShowMessage] = useState(false);
  const [content, setContent] = useState(
    (previewImages && previewImages.newReleaseContent) || null
  );
  const [opeNewReleaseDialog, setOpeNewReleaseDialog] = useState(false);

  // validate golive, start and end date to show what new content dialog box
  const isActiveRelease = ({ startDate, endDate, goLive }) => {
    let isActive = false;
    const currentDate = new Date();

    if (
      new Date(startDate) <= currentDate &&
      new Date(endDate) >= currentDate &&
      goLive === true
    ) {
      isActive = true;
    }

    return isActive;
  };

  useEffect(() => {
    async function fetchReleaseContent() {
      // fetching the list of users who opted for not to view Whats new conetent
      const listOfUsers = await getUserPrefereneceToViewNewReleaseContent(
        RELEASE_NUMBER
      );

      const isNewReleaseContentVisible = listOfUsers.find(
        user => user === userId
      );

      if (!isNewReleaseContentVisible) {
        // Fetch Whats new Content for a release
        const response = await getNewReleaseContent(RELEASE_NUMBER);
        // set content only if it has slides to showcase and sow pop up
        if (
          isActiveRelease(response) &&
          response.screens &&
          response.screens.length > 0
        ) {
          // sorting image slides with its index number
          const sortedContent = response.screens.sort(sortByProp('index'));

          sortedContent.forEach(img => {
            const bgImg = new Image();
            bgImg.src = img.url;
          });
          // set the sorted content
          setContent(sortedContent);
          setOpeNewReleaseDialog(true);
        } else {
          // If release date has been expired then release dialog box state should be false
          setShowNewReleaseContent(false);
        }
      } else {
        setShowNewReleaseContent(false);
      }
    }

    if (!previewImages) {
      if (RELEASE_NUMBER && showNewReleaseContent) {
        fetchReleaseContent();
      }
    } else {
      setContent(previewImages.newReleaseContent);
      setOpeNewReleaseDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RELEASE_NUMBER]);

  const handleClose = async () => {
    // informing parent to close
    if (closeNewReleaseDialog) {
      closeNewReleaseDialog(false);
    }
    // close the modal
    setOpeNewReleaseDialog(false);
    // set filter to showNewRelaseContent to false
    setShowNewReleaseContent(false);
    // save the information in cookie
    updateCookieForNewReleaseContent(userId);
    // if do not shhow message checkox is selected call the api to update preference in db
    if (doNotShowMessage) {
      await updateUserPrefereneceForViewingNewReleaseContent(RELEASE_NUMBER);
    }
  };

  const handleDoNotShowMessageClick = async () => {
    setDoNotShowMessage(!doNotShowMessage);
  };

  return (
    <CustomDialog
      id="newReleaseDialog"
      onClose={handleClose}
      open={opeNewReleaseDialog}
      maxWidth="sm"
      className="new-release-dialog"
      data-testid="new-release-dialog"
    >
      <DialogHeader
        onClose={handleClose}
        title={NEW_RELEASE_DIALOG_TITLE}
        className="new-release-dialog__header"
      />
      {/* Content */}
      <DialogContent className="new-release-dialog__content-container">
        <div className="new-release-dialog__content">
          {content && <Carousel slides={content} name="whats-new-dialog" />}
        </div>
      </DialogContent>
      {/* Footer */}
      <div className="new-release-dialog__footer">
        <Checkbox
          checked={doNotShowMessage}
          onClick={handleDoNotShowMessageClick}
          color="primary"
          data-testid="doNotShow-checkbox"
          inputProps={{ 'aria-label': 'do-not-show-whats-new-dialog-checkbox' }}
        />
        <span>{NEW_RELEASE_DIALOG_FOOTER_MSG}</span>
      </div>
    </CustomDialog>
  );
};

NewReleaseDialog.propTypes = {
  store: PropTypes.shape({
    Config: PropTypes.shape({
      RELEASE_NUMBER: PropTypes.string.isRequired
    }),
    UserInfo: PropTypes.shape({
      userId: PropTypes.string.isRequired
    }),
    Filters: PropTypes.shape({
      setShowNewReleaseContent: PropTypes.func.isRequired,
      showNewReleaseContent: PropTypes.string.isRequired,
      updateCookieForNewReleaseContent: PropTypes.func.isRequired
    })
  }),
  previewImages: PropTypes.arrayof(PropTypes.string),
  closeNewReleaseDialog: PropTypes.func.isRequired
};

export default hot(inject('store')(observer(NewReleaseDialog)));
