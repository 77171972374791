/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { TextField, Button } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import { isEmpty } from 'lodash';
import {
  CustomDialog,
  DialogHeader,
  DialogContent,
  DialogActions
} from '../common/Dialog';
import Constants from '../../constants';
import './AIUserAccessManager.style.scss';
import { addUserToAIAccess } from '../../services/addUserToAIAccess';

const AIUserAccessManager = props => {
  const {
    store: {
      MessageDialog: { showErrorMessageDialog }
    }
  } = props;

  const defaultEntityUrn =
    'urn:pearson:entity:48488426-aac5-48e5-9592-fd63f83d52b8';

  const [openDialog, setOpenDialog] = useState(false);
  const [userIds, setUserIds] = useState([]);
  const [entityUrn, setEntityUrn] = useState(defaultEntityUrn);
  const [entityUrnError, showEntityUrnError] = useState(false);
  const [disableAdd, setDisableAdd] = useState(true);
  const [
    validationMessageForEntityUrn,
    setValidationMessageForEntityUrn
  ] = useState('');
  const [showResponseDialog, setShowResponseDialog] = useState(false);

  const maxUserLength = 50;

  const validationMessageForUserList = Constants.MAX_ALLOWED_USER_LENGTH(
    `${maxUserLength}`
  );

  const openAIUserAccessManagerDialog = () => {
    setOpenDialog(true);
  };

  const closeAIUserAccessManagerDialog = () => {
    setOpenDialog(false);
    setShowResponseDialog(false);
    setUserIds([]);
    setEntityUrn(defaultEntityUrn);
    showEntityUrnError(false);
    setDisableAdd(true);
    setShowResponseDialog(false);
    setValidationMessageForEntityUrn('');
  };

  const handleUserListChange = e => {
    const input = e.target.value;
    const fetchUsers = !isEmpty(input.trim()) ? input.split(',') : [];
    if (fetchUsers.length > maxUserLength) {
      setUserIds(fetchUsers.slice(0, maxUserLength));
      setDisableAdd(false);
    } else if (fetchUsers.length === 0) {
      setDisableAdd(true);
      setUserIds(fetchUsers);
    } else {
      setUserIds(fetchUsers);
      setDisableAdd(false);
    }
  };

  const handleEntityUrnChange = e => {
    const urn = e.target.value;
    setEntityUrn(urn.trim());
    showEntityUrnError(false);
  };

  const handleAdd = async () => {
    if (
      !entityUrn ||
      !entityUrn.startsWith('urn:pearson:entity') ||
      !/^urn:pearson:entity:[a-z0-9-]+$/gi.test(entityUrn)
    ) {
      setValidationMessageForEntityUrn(Constants.INCORRECT_ENTITY_URN);
      showEntityUrnError(true);
    } else {
      const inputUsers = userIds
        .map(user => user.replace(/(\r\n|\n|\r|\s)/gm, '').trim())
        .filter(user => !isEmpty(user));
      const response = await addUserToAIAccess(entityUrn, inputUsers);
      if (response.status === 404) {
        showErrorMessageDialog(
          response.data.message,
          Constants.DIALOG_TITLE_NOT_FOUND
        );
      } else {
        setShowResponseDialog(true);
      }
    }
  };

  return (
    <>
      <button
        className="user-detail__user-manager"
        type="button"
        onClick={openAIUserAccessManagerDialog}
        data-testid="ai-user-access-link"
      >
        <GroupIcon />
        {Constants.AI_USER_ACCESS_MANAGER}
      </button>
      {showResponseDialog ? (
        <CustomDialog
          onClose={closeAIUserAccessManagerDialog}
          open={showResponseDialog}
          className="user-manager-dialog"
          maxWidth={false}
          disableBackdropClick
        >
          <DialogHeader
            onClose={closeAIUserAccessManagerDialog}
            className="user-manager-title"
            title={Constants.AI_USER_ACCESS_MANAGER}
          />
          <DialogContent
            className="user-manager-content"
            data-testid="userManagerDialogContent"
          >
            {Constants.SUCCESS_MESSAGE_FOR_USER_ADDED_TO_AI_ACCESS}
          </DialogContent>
          <DialogActions className="dialog-actions">
            <Button
              className="btnSave"
              onClick={closeAIUserAccessManagerDialog}
              variant="contained"
              color="primary"
              aria-label="ok-ai-user-access"
              data-testid="button-ok-ai-user-access"
            >
              {Constants.OK}
            </Button>
          </DialogActions>
        </CustomDialog>
      ) : (
        <CustomDialog
          onClose={closeAIUserAccessManagerDialog}
          open={openDialog}
          className="user-manager-dialog"
          maxWidth={false}
          disableBackdropClick
        >
          <DialogHeader
            onClose={closeAIUserAccessManagerDialog}
            className="user-manager-title"
            title={Constants.AI_USER_ACCESS_MANAGER}
          />
          <DialogContent
            className="user-manager-content"
            data-testid="userManagerDialogContent"
          >
            <form
              noValidate
              onSubmit={e => e.preventDefault()}
              autoComplete="off"
              data-testid="form"
            >
              <div className="ai-access-input-details-container">
                <TextField
                  required
                  label="User List"
                  value={userIds}
                  id="userListField"
                  className="input-control user-list"
                  helperText={validationMessageForUserList}
                  variant="filled"
                  multiline
                  inputProps={{
                    'aria-label': 'ai-user-access-list'
                  }}
                  onChange={handleUserListChange}
                />
                <TextField
                  required
                  error={entityUrnError}
                  label={
                    entityUrnError ? validationMessageForEntityUrn : 'EntityURN'
                  }
                  id="userListField"
                  className="input-control"
                  value={entityUrn}
                  variant="filled"
                  inputProps={{
                    'aria-label': 'ai-user-access-entityUrn'
                  }}
                  onChange={handleEntityUrnChange}
                />
              </div>
            </form>
          </DialogContent>
          <DialogActions className="dialog-actions">
            <Button
              className="btnCancel"
              onClick={closeAIUserAccessManagerDialog}
            >
              {Constants.USER_MANAGEMENT_REMOVE_USER_DIALOG_CANCEL}
            </Button>
            <Button
              className="btnSave"
              onClick={handleAdd}
              variant="contained"
              color="primary"
              disabled={disableAdd || entityUrnError}
              aria-label="add-ai-user-access"
              data-testid="button-add-ai-user-access"
            >
              {Constants.USER_MANAGEMENT_ADD_NEW_USER_DIALOG_ADD_BUTTON}
            </Button>
          </DialogActions>
        </CustomDialog>
      )}
    </>
  );
};

AIUserAccessManager.propTypes = {
  store: PropTypes.shape({
    MessageDialog: PropTypes.shape({
      showErrorMessageDialog: PropTypes.func.isRequired
    })
  })
};

export default inject('store')(observer(AIUserAccessManager));
