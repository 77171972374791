/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { hot } from 'react-hot-loader/root';
import {
  Select,
  MenuItem,
  FormControl,
  Button,
  RadioGroup,
  InputLabel,
  IconButton,
  TextField,
  FormControlLabel,
  Radio
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import CloseIcon from '@mui/icons-material/Close';

import { updateFileUrls } from '../../services/updateFileUrls';
import { startFileUplaodInChunks } from '../../utils/fileUpload';
import FileUrlsActivity from './FileUrlsActivity';
import Constants from '../../constants';
import { isValidURL } from '../../utils/utils';
import './FileUrlsMgmt.style.scss';

const FileUrlsMgmt = props => {
  const {
    store: {
      FileUrls: { getKeys }
    }
  } = props;

  const featuresOption = [
    { value: 'uploadFile', label: 'Upload File' },
    { value: 'linkUrl', label: 'Link Url' }
  ];
  const [selectedKey, setSelectedKey] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [file, setFile] = useState('');
  const [featureType, setFeatureType] = useState('uploadFile');

  const [keyError, setKeyError] = useState('');
  const [fileError, setFileError] = useState('');

  const [forceUpdateActivities, setForceUpdateActivities] = useState(0);

  const onChange = e => {
    setSelectedKey(e.target.value);
    setKeyError('');
  };

  const checkError = () => {
    let hasError = false;
    setKeyError('');
    setFileError('');

    if (selectedKey === '') {
      setKeyError(Constants.SELECT_KEY_MESSAGE);
      hasError = true;
    }
    // if upload file Radio button is selected and file is not uploaded set the error
    if (featureType === 'uploadFile' && file === '') {
      setFileError(Constants.SELECT_FILE_MESSAGE);
      hasError = true;
    }
    // if linkUrl Radio button is selected and value  is not provided set the error
    if (featureType === 'linkUrl' && fileUrl === '') {
      setFileError('Add link url');
      hasError = true;
    }

    if (
      featureType === 'linkUrl' &&
      fileUrl !== '' &&
      !isValidURL(fileUrl.trim())
    ) {
      setFileError(Constants.INVALID_URL);
      hasError = true;
    }

    return hasError;
  };

  const handleResetFile = () => {
    setFile('');
    document.getElementById('fileInput').value = '';
  };

  const handleSave = async () => {
    if (checkError()) {
      return false;
    }
    if (file) {
      const s3Location = await startFileUplaodInChunks(file);
      handleResetFile();
      await updateFileUrls(selectedKey, s3Location);
    } else {
      await updateFileUrls(selectedKey, fileUrl.trim(), true);
    }
    setSelectedKey('');
    setFileUrl('');
    setForceUpdateActivities(forceUpdateActivities + 1);
    return true;
  };

  const uploadFile = async event => {
    event.preventDefault();
    setFile(event.target.files && event.target.files[0]);
    setFileError('');
  };

  const onChangingFileUrl = e => {
    setFileError('');
    setFileUrl(e.target.value);
  };
  const handleFeatureChange = event => {
    setFile('');
    setFileUrl('');
    setKeyError('');
    setFileError('');
    setFeatureType(event.target.value);
  };
  const renderOptions = options => {
    const menuItems = options.map(option => {
      const { label: name, value: optionValue } = option;
      return (
        <MenuItem
          key={name.trim()}
          value={optionValue}
          className="select-menu-item button-alignment__menu-item"
          aria-label={`fileurls-${name}`}
        >
          {name}
        </MenuItem>
      );
    });
    return [
      <MenuItem
        value=""
        key="option_none"
        className="select-menu-item button-alignment__menu-item"
        aria-label="fileurls-none"
        name="none"
      >
        {Constants.NONE}
      </MenuItem>,
      menuItems
    ];
  };

  return (
    <div className="fileurls-mgmt">
      <fieldset className="fileurls-mgmt-form">
        <legend>{Constants.UPDATE_FILE_URL}</legend>
        <FormControl>
          <InputLabel>{Constants.SELECT_KEY}</InputLabel>
          <Select
            className="select-key"
            disableUnderline
            value={selectedKey}
            onChange={onChange}
            title={selectedKey}
            inputProps={{
              'aria-label': 'file-urls-select',
              'data-testid': 'file-urls-select'
            }}
          >
            {renderOptions(getKeys)}
          </Select>
          <div className="error">{keyError}</div>
        </FormControl>
        <RadioGroup
          row
          aria-label="featuretype"
          name="featuretype"
          value={featureType}
          onChange={handleFeatureChange}
          data-testid="featuretype"
          className="featuretype-radio-buttons"
        >
          {featuresOption.map(feature => (
            <FormControlLabel
              value={feature.value}
              key={feature.value}
              control={
                <Radio
                  color="primary"
                  inputProps={{
                    'aria-label': 'NT-tool-notification-radio-btn'
                  }}
                />
              }
              label={feature.label}
              data-testid={feature.value}
            />
          ))}
        </RadioGroup>

        <div className="upload-thumbnail-image">
          {featureType === 'uploadFile' && (
            <div className="upload-file">
              <input
                type="file"
                required
                id="fileInput"
                className="file-input"
                data-testid="fileInput"
                onChange={e => uploadFile(e)}
                aria-label="NT-tool-choose-thumbnail-input"
              />

              <label htmlFor="fileInput">
                <IconButton
                  color="primary"
                  component="span"
                  aria-label="NT-tool-choose-thumbnail-icon"
                  size="large"
                >
                  <ImageIcon fontSize="large" />
                </IconButton>
                {Constants.CHOOSE_FILE}
              </label>
              <div className="file-name">
                {file && (
                  <>
                    <span>{file.name}</span>
                    <span>
                      <IconButton
                        aria-label="NT-tool-selected-thumbnail-close"
                        className="close-thumbnail-icon"
                        onClick={handleResetFile}
                        data-testid="closeImageIcon"
                        size="large"
                      >
                        <CloseIcon />
                      </IconButton>
                    </span>
                  </>
                )}
              </div>
            </div>
          )}
          {featureType === 'linkUrl' && (
            <div className="add-hyperlink">
              <TextField
                variant="standard"
                key="linkUrl"
                id="linkUrl"
                label="Link Url"
                value={fileUrl}
                onChange={e => onChangingFileUrl(e)}
                title={fileUrl}
                inputProps={{
                  'aria-label': 'add-hyperlink',
                  'data-testid': 'add-hyperlink'
                }}
                fullWidth
              />
            </div>
          )}
          <div className="error">{fileError}</div>
        </div>
        <div className="button-wrapper">
          <Button
            type="button"
            className="btn-save"
            onClick={handleSave}
            data-testid="btn-save"
            aria-label="NT-tool-save"
          >
            {Constants.SAVE}
          </Button>
        </div>
      </fieldset>
      <FileUrlsActivity forceUpdate={forceUpdateActivities} />
    </div>
  );
};

FileUrlsMgmt.propTypes = {
  store: PropTypes.shape({
    FileUrls: PropTypes.shape({
      getKeys: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    })
  })
};

export default hot(inject('store')(observer(FileUrlsMgmt)));
