/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { hot } from 'react-hot-loader/root';

import { getUserAvatar } from '../../utils';
import UserMenu from './UserMenu';

import './UserDetail.style.scss';

const UserDetail = props => {
  const {
    store: {
      NotificationDrawer: { isNotificationDrawerOpen, closeNotificationDrawer },
      UserInfo: { email, firstName }
    }
  } = props;

  const firstCharOfUserName = firstName ? firstName[0].toUpperCase() : '';

  const closeDrawer = () => {
    if (isNotificationDrawerOpen) {
      closeNotificationDrawer();
    }
  };

  return (
    <div className="user-detail">
      <div className="user-detail__wrapper">
        <div>
          <div className="user-detail__name">{firstName}</div>
          <div className="user-detail__email">{email}</div>
        </div>
        <div
          className="user-detail__avatar"
          role="button"
          tabIndex={0}
          onClick={closeDrawer}
          onKeyDown={closeDrawer}
          data-testid="user-detail__avatar"
        >
          {firstCharOfUserName}
          <div style={getUserAvatar(email)}></div>
        </div>
      </div>
      <div className="user-detail__menu">
        <UserMenu />
      </div>
    </div>
  );
};

UserDetail.propTypes = {
  store: PropTypes.shape({
    NotificationDrawer: PropTypes.shape({
      closeNotificationDrawer: PropTypes.func.isRequired,
      isNotificationDrawerOpen: PropTypes.bool.isRequired
    }),
    UserInfo: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired
    })
  })
};

export default hot(inject('store')(observer(UserDetail)));
