/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MuiDialogContent from '@mui/material/DialogContent';

/**
 * Render Dialog content.
 */
const DialogContent = props => {
  const { children, ...otherProps } = props;
  return (
    // TODO - need to change webpack config for css classes.
    <Fragment>
      <MuiDialogContent className="udb-dialog-content" {...otherProps}>
        {children}
      </MuiDialogContent>
    </Fragment>
  );
};

DialogContent.propTypes = {
  children: PropTypes.node.isRequired
};

export default DialogContent;
