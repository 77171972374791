/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import { axiosFetch } from './axiosFetch';
import apiConstants from '../constants/apiConstants';
import {
  urlModifier,
  getHeaders,
  parseURLParams,
  postHeaders,
  putHeaders
} from '../utils';

export const getTocStructure = async dURN => {
  const replacement = { '%dURN%': dURN };
  const url = parseURLParams(replacement, apiConstants.GET_TOC_STRUCTURE);
  const apiPath = urlModifier(url);
  const reqOptions = {
    ...getHeaders()
  };

  const response = await axiosFetch(reqOptions, apiPath);
  return response.data;
};

export const publishLesson = async (workurn, payload) => {
  const replacement = { '%workurn%': workurn };
  const url = parseURLParams(replacement, apiConstants.PUBLISH_LESSON);
  const apiPath = urlModifier(url);
  const reqOptions = {
    ...postHeaders(),
    body: payload
  };

  const response = await axiosFetch(reqOptions, apiPath);
  return response.data;
};

export const updateStatusOfPublishedLesson = async listOfTransactions => {
  const apiPath = urlModifier(apiConstants.PUBLISH_LESSONS_STATUS);
  const reqOptions = {
    ...putHeaders(),
    body: listOfTransactions,
    config: { backgroundProcess: true }
  };

  const response = await axiosFetch(reqOptions, apiPath);
  return response.data;
};

export const getPublishLessonDetailsFromDb = async (
  durn,
  page,
  pageSize,
  backgroundProcess = true
) => {
  const replacement = { '%durn%': durn };
  const url = parseURLParams(
    replacement,
    apiConstants.GET_PUBLISH_LESSON_DETAILS_FROM_DB
  );
  const apiPath = urlModifier(url);

  // adding Timestamp in params so that result is not cached
  const timestamp = new Date().toISOString();
  const params = { page, pageSize, timestamp };

  const reqOptions = {
    ...getHeaders(),
    config: { backgroundProcess }
  };

  const response = await axiosFetch(reqOptions, apiPath, params);
  return response.data;
};
