/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

// GA4 event constants
export default {
  PROJECT_LINK_CLICKED: 'project_link_clicked',
  CYPRESS_AUTHORING_LINK_CLICKED: 'cypress_authoring_link_clicked',
  CYPRESS_PLUS_AUTHORING_LINK_CLICKED: 'cypress_plus_authoring_link_clicked',
  PREVIEW_LINK_CLICKED: 'preview_link_clicked',
  EXPORT_LINK_CLICKED: 'export_link_clicked',
  PREFLIGHT_LINK_CLICKED: 'preflight_link_clicked',
  IMPORT_LINK_CLICKED: 'import_link_clicked',
  PEER_REVIEW_LINK_CLICKED: 'peer_review_link_clicked',
  EDIT_WITH_ELM_ICON_CLICKED: 'edit_with_elm_icon_clicked',
  CREATE_NEW_VERSION_CLICKED: 'create_new_version_clicked',
  TEAM_MEMBERS_ICON_CLICKED: 'team_members_icon_clicked',
  ALFRESCO_ICON_CLICKED: 'alfresco_icon_clicked',
  EDIT_PROJECT_SETTINGS_CLICKED: 'edit_project_settings_clicked',
  PREFLIGHT_RUN_REPORT: 'preflight_run_report_clicked',
  PREFLIGHT_DOWNLOAD_REPORT: 'preflight_download_report_clicked',
  MATHML_RUN_REPORT: 'mathml_run_report_clicked',
  ALFRESCO_SCRIPT_RUN_REPORT: 'alfresco_script_run_report_clicked',
  EPUB_ACCESSIBILITY_RUN_REPORT: 'epub_accessibility_run_report_clicked',
  EPUB_ACCESSIBILITY_DOWNLOAD_REPORT:
    'epub_accessibility_download_report_clicked',
  ASSESSMENT_AND_INTERACTIVE_UPDATE_ALL:
    'assessment_and_interactive_update_all',
  ASSESSMENT_AND_INTERACTIVE_SINGLE_UPDATE:
    'assessment_and_interactive_single_update',
  ASSESSMENT_AND_INTERACTIVE_TAB_CLICKED:
    'assessment_and_interactive_tab_clicked',
  ASSESSMENT_AND_INTERACTIVE_VIEW_ALL_IN_ELM_CLICKED:
    'assessment_and_interactive_view_all_in_elm_clicked',
  ASSESSMENT_AND_INTERACTIVE_VIEW_IN_ELM_CLICKED:
    'assessment_and_interactive_view_in_elm_clicked',
  ASSESSMENT_AND_INTERACTIVE_OPEN_IN_CYPRESS_CLICKED:
    'assessment_and_interactive_open_in_cypress_clicked',
  IMPORT_TAB_CLICKED: 'import_tab_clicked',
  EXPORT_TAB_CLICKED: 'export_tab_clicked',
  ACTIVITIES_TAB_CLICKED: 'activities_tab_clicked',
  PROJECT_VERSION_TAB_CLICKED: 'project_version_tab_clicked',
  IMPORT_PDF: 'import_pdf',
  DISTRIBUTION: 'distribution',
  CREATE_NEW_PROJECT_CLICKED: 'create_new_project_clicked',
  // paice events are captured by dynamically generating event name using projectKey
  // eventCategory name-
  VIEW_PUBLISHING_DETAILS: 'view_publishing_details_clicked',
  PUBLISH_BUTTON_CLICKED: 'publish_button_clicked',
  TRANSACTION_VIEWED_IN_PUBLISHING_LOG: 'transaction_viewed_in_publishing_log',
  PROJECT_VERSION_INFO_CHECKED: 'project_version_info_checked',
  PROJECT_VERSION_STATUS_CHECKED: 'project_version_status_checked',
  ADD_USER_TO_PROJECT: 'added_users_to_projects',
  USER_PREFERENCE_SAVED: 'user_preference_saved',

  // blueprint
  BLUEPRINT_DEEPLINK_CLICKED: 'blueprint_deeplink_clicked',
  SIGNOUT_BUTTON_CLICKED: 'signout_button_clicked',
  COMMENTS_ICON_CLICKED: 'comments_icon_clicked',
  STATISTICS_ICON_CLICKED: 'statistics_icon_clicked',
  GO_LIVE_ICON_CLICKED: 'go_live_icon_clicked',

  // lesson builder
  LESSON_PUBLISH_BUTTON_CLICKED: 'lesson_publish_button_clicked',
  LESSON_BUILDER_LINK_CLICKED: 'lesson_builder_link_clicked'
};
