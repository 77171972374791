/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { toJS } from 'mobx';
import { types } from 'mobx-state-tree';
import ProjectVersionData from './Versions';

import {
  getProjectWithVersionInfo,
  getProjectByDurn
} from '../services/getProject';
import { updateAlfrescoDetails } from '../services/alfrescoServiceObject';
import { updateAlfrescoEpubDetails } from '../services/updateAlfrescoEpubDetails';
import { toggleFavourite } from '../services/toggleFavourite';
import thumbnailImage from '../../public/images/pearson_thumbnail.png';
import Alfresco from './Alfresco';
import { getVersions } from '../services/getVersions';
import Constants from '../constants';
import PublishInfo from './PublishInfo';
import ThemeItem from './ThemeItems';
import ProjectNotes from './ProjectNotes';
import { getAssociatedThemeList } from '../services/getAssociatedThemeList';
import { updateContentRole } from '../services/updateContentRole';
import LessonPublishingStatus from './LessonPublishingStatus';
import { getPublishLessonDetailsFromDb } from '../services/lessonBuilderService';

export default types
  .model('Project', {
    id: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    authorName: types.maybeNull(types.string),
    thumbnail: types.maybeNull(types.string, ''),
    entityUrn: types.maybeNull(types.string),
    bookCover: types.maybeNull(types.string),
    versions: types.array(ProjectVersionData),
    status: types.maybeNull(types.string),
    publishStatus: types.maybeNull(types.string),
    alfresco: types.maybeNull(Alfresco),
    alfrescoEpubDetail: types.maybeNull(Alfresco),
    backgroundColor: types.maybeNull(types.string),
    tcm: types.frozen(),
    productId: types.maybeNull(types.string),
    isFavourite: false,
    isLocked: false,
    permissions: types.array(types.string),
    hasMoreVersions: false,
    userCount: 0,
    isProjectEdited: false,
    lineOfBusiness: types.maybeNull(types.string),
    dateCreated: types.maybeNull(types.string),
    latestVersionHref: '',
    instructorResourceUrl: types.maybeNull(types.string),
    publishDestination: types.maybeNull(types.string),
    publishInfo: types.maybeNull(PublishInfo),
    userApprover: types.maybeNull(types.string),
    userApproverFirstName: types.maybeNull(types.string),
    totalVersionCount: types.maybeNull(types.number),
    statusDisplayText: types.maybeNull(types.string),
    distributionDisplayText: types.maybeNull(types.string),
    submittedOnDisplayText: types.maybeNull(types.string),
    previousDistributableUrn: types.maybeNull(types.string),
    glossaryOption: Constants.ALPHABETICALLY,
    subject: types.maybeNull(types.string),
    keyStage: types.maybeNull(types.string),
    skills: types.maybeNull(types.string),
    property: types.maybeNull(types.string),
    associatedTheme: types.array(ThemeItem),
    sharingContextRole: types.maybeNull(types.string),
    isEpubProject: false,
    isTccProject: false,
    isEbookProject: false,
    origin: types.maybeNull(types.string),
    isAutoNumberingEnabled: false,
    roleId: types.maybeNull(types.string),
    isAddUserPermissionExist: types.maybeNull(types.boolean),
    isCypressPlusEnabled: false,
    isH5PContentEnabled: false,
    isProjectCancelled: false,
    quadFolder: types.maybeNull(types.string),
    sourcetitle: types.maybeNull(types.string),
    sourcedurn: types.maybeNull(types.string),
    sourceenv: types.maybeNull(types.string),
    hasLiveVersion: false,
    category: types.maybeNull(types.string),
    projectNotes: types.maybeNull(ProjectNotes),
    autoNumberingTransactionId: types.maybeNull(types.string),
    revelAudioFlagOverride: types.maybeNull(types.string),
    defaultLearningFramework: types.maybeNull(types.string),
    isBlueprintIPMProjectAssociated: false,
    ipmProjectNumber: types.maybeNull(types.string),
    blueprintProductId: types.maybeNull(types.string),
    productInfoUrn: types.maybeNull(types.string),
    lessonPublishingStatus: types.array(LessonPublishingStatus)
  })
  .views(self => ({
    get hasOwnerRole() {
      return self.sharingContextRole === Constants.OWNER;
    },
    get hasSubscriberRole() {
      return self.sharingContextRole === Constants.SUBSCRIBER;
    },
    get hasSubscription() {
      return (
        self.sharingContextRole === Constants.OWNER ||
        self.sharingContextRole === Constants.SUBSCRIBER
      );
    }
  }))
  .actions(self => ({
    update({
      id,
      name,
      authorName,
      thumbnail,
      entityUrn,
      bookCover,
      status,
      alfresco,
      alfrescoEpubDetail,
      backgroundColor,
      tcm,
      gridId,
      productId,
      isFavourite,
      isLocked,
      permissions,
      userCount = 0,
      lineOfBusiness,
      dateCreated,
      instructorResourceUrl,
      publishInfo,
      userApprover,
      userApproverFirstName,
      statusDisplayText,
      distributionDisplayText,
      submittedOnDisplayText,
      previousDistributableUrn,
      glossary,
      subject,
      keyStage,
      skills,
      property,
      segment,
      level,
      sharingContextRole,
      isEpubProject,
      isTccProject,
      isEbookProject,
      origin,
      isAutoNumberingEnabled,
      roleId,
      isAddUserPermissionExist,
      isCypressPlusEnabled,
      isH5PContentEnabled,
      isProjectCancelled,
      quadFolder,
      sourcetitle,
      sourcedurn,
      sourceenv,
      category,
      importantNotes,
      autoNumberingTransactionId,
      revelAudioFlagOverride,
      defaultLearningFramework,
      isBlueprintIPMProjectAssociated,
      ipmProjectNumber,
      blueprintProductId,
      productInfoUrn
    }) {
      // extracting status and setting its default value to '' if no status is found
      const { projectStatus = '', publishStatus = null } = status;
      self.id = id;
      self.name = name;
      self.authorName = authorName || null;
      self.thumbnail = thumbnail;
      self.entityUrn = entityUrn;
      self.bookCover = bookCover;
      self.status = projectStatus.toLowerCase();
      self.publishStatus = publishStatus;
      self.alfresco = alfresco;
      self.alfrescoEpubDetail = alfrescoEpubDetail;
      self.backgroundColor = backgroundColor;
      self.tcm = tcm || {};
      self.gridId = gridId;
      self.productId = productId;
      self.isFavourite = !!isFavourite;
      self.isLocked = isLocked;
      self.permissions = permissions;
      self.userCount = userCount;
      self.lineOfBusiness = lineOfBusiness;
      self.dateCreated = dateCreated;
      self.instructorResourceUrl = instructorResourceUrl;
      self.publishDestination =
        publishInfo && publishInfo.publishDestination
          ? publishInfo.publishDestination
          : null;
      self.publishInfo = publishInfo;
      self.userApprover = userApprover;
      self.userApproverFirstName = userApproverFirstName;
      self.statusDisplayText = statusDisplayText;
      self.distributionDisplayText = distributionDisplayText;
      self.submittedOnDisplayText = submittedOnDisplayText;
      self.previousDistributableUrn = previousDistributableUrn;
      self.glossaryOption = glossary || Constants.ALPHABETICALLY;
      self.subject = subject;
      self.keyStage = keyStage;
      self.skills = skills;
      self.property = property;
      self.segment = segment;
      self.level = level;
      self.sharingContextRole = sharingContextRole;
      self.isEpubProject = isEpubProject;
      self.isTccProject = isTccProject;
      self.isEbookProject = isEbookProject;
      self.origin = origin;
      self.isAutoNumberingEnabled = isAutoNumberingEnabled;
      self.roleId = roleId;
      self.isAddUserPermissionExist = isAddUserPermissionExist;
      self.isCypressPlusEnabled = isCypressPlusEnabled;
      self.isH5PContentEnabled = isH5PContentEnabled;
      self.isProjectCancelled = isProjectCancelled;
      self.quadFolder = quadFolder;
      self.sourcetitle = sourcetitle;
      self.sourcedurn = sourcedurn;
      self.sourceenv = sourceenv;
      self.category = category;
      self.projectNotes = importantNotes;
      self.autoNumberingTransactionId = autoNumberingTransactionId;
      self.revelAudioFlagOverride = revelAudioFlagOverride;
      self.defaultLearningFramework = defaultLearningFramework;
      self.isBlueprintIPMProjectAssociated = isBlueprintIPMProjectAssociated;
      self.ipmProjectNumber = ipmProjectNumber;
      self.blueprintProductId = blueprintProductId;
      self.productInfoUrn = productInfoUrn;
    },

    async updateCategory(dUrn, category) {
      self.category = category;
    },

    updatePublishInfo(statusObj) {
      self.publishInfo = statusObj ? statusObj.publishInfo : null;
    },

    updatePublishStatus(publishStatus, statusDisplayText) {
      self.publishStatus = publishStatus;
      self.statusDisplayText = statusDisplayText;
    },

    // update total number of version available in store
    updateTotalVersionCount(versionCount) {
      self.totalVersionCount = versionCount;
    },

    updateVersionList(versionsData) {
      self.versions = [];
      versionsData.forEach((version, index) => {
        const versionCount = self.totalVersionCount;

        // generating version numbers from total count of versions
        self.versions.push({ ...version, versionNo: versionCount - index });
      });
    },

    updatePublishingStatus(lessonPublishingStatus) {
      self.lessonPublishingStatus = [];
      lessonPublishingStatus.forEach(publishingStatus => {
        self.lessonPublishingStatus.push(publishingStatus);
      });
    },

    updateCurrentVersionData(durn, updatedField) {
      const versionData = self.versions.find(version => durn === version.id);
      if (versionData && versionData.name) {
        Object.keys(updatedField).forEach(field => {
          versionData[field] = updatedField[field];
        });
      }
    },

    setHasLiveVersion(val) {
      self.hasLiveVersion = val;
    },

    async loadProjectWithVersions(id, entityUrn, updateVersion = true) {
      const {
        projectDetails: project,
        versions,
        hasMoreVersions,
        totalVersionCount,
        _links: { latestVersion: { href } } = { latestVersion: { href: '' } }
      } = await getProjectWithVersionInfo(id, entityUrn);
      // update total number of version available in store
      self.updateTotalVersionCount(totalVersionCount);

      // updateVersion will determine that we need to update the whole version model or not
      if (updateVersion) {
        self.updateVersionList(versions);
      }
      self.update(project);
      if (project.isH5PContentEnabled) {
        const publishingStatus = await getPublishLessonDetailsFromDb(
          project.id,
          1,
          5,
          false
        );
        self.updatePublishingStatus(publishingStatus);
      } else {
        self.updatePublishingStatus([]);
      }
      self.updateHasMoreVersions(hasMoreVersions);

      self.updateLatestVersionHref(href);

      return { project, versions, hasMoreVersions, latestVersionHref: href };
    },

    updateHasMoreVersions(hasMoreVersions) {
      self.hasMoreVersions = hasMoreVersions;
    },

    updateLatestVersionHref(latestsVersionHref) {
      self.latestVersionHref = latestsVersionHref;
    },

    async loadProjectById(id) {
      const { projectDetails: project } = await getProjectByDurn(id);
      return project;
    },

    updateAlfrescoLocation(data) {
      self.alfresco = data;
      self.updateCurrentVersionData(self.id, { alfresco: data });

      // Updating isProjectEdited flag to true
      self.updateIsProjectEdited(true);
    },

    updateAlfrescoEpubLocation(data) {
      self.alfrescoEpubDetail = data;
    },

    async updateAlfrescoEpubDetail(id, data) {
      const response = await updateAlfrescoEpubDetails(id, data);
      self.updateAlfrescoEpubLocation(response.data.data);
      self.updateIsProjectEdited(true);
    },

    updateProjectThumbnail(thumbnail) {
      self.thumbnail = thumbnail;
    },

    updateProjectBookCover(bookCover) {
      self.bookCover = bookCover;
    },

    async updateAlfrescoDetails(alfrescoData) {
      // API call for linking/updating alfresco
      const response = await updateAlfrescoDetails(self, alfrescoData);
      if (Object.keys(response).length > 0) {
        // Updating alfresco data in mobx
        self.updateAlfrescoLocation(response.data.alfresco);
        if (response.data.thumbnail !== undefined) {
          // updating thumbnail and book cover in mobx.
          self.updateProjectThumbnail(response.data.thumbnail);
        } else {
          // If no thumbnail, setting default image.
          self.updateProjectThumbnail(thumbnailImage);
        }
        if (response.data.bookCover !== undefined) {
          // updating book cover in mobx.
          self.updateProjectBookCover(response.data.bookCover);
        } else {
          // If no thumbnail, setting default image.
          self.updateProjectBookCover(thumbnailImage);
        }
      }
      return response;
    },

    updateProjectTitleAuthor(projectTitle, authorName) {
      self.name = projectTitle.trim();
      self.authorName = authorName.trim();

      // Updating isProjectEdited flag to true
      self.updateIsProjectEdited(true);
    },

    updateProjectTrackChangeStatus(tcmData) {
      const tcmObj = {
        user: tcmData.user,
        userIp: tcmData.userIp,
        activated: tcmData.activated
      };
      self.tcm = tcmObj;

      // Updating isProjectEdited flag to true
      self.updateIsProjectEdited(true);
    },

    updateIsAutoNumberingEnabled(value) {
      self.isAutoNumberingEnabled = value;
      self.updateIsProjectEdited(true);
    },

    /**
     * @param {*} toggleFavoriteEntityUrn - Method to add/remove favorite entity urn from array to avoid api call.
     */

    async toggleFavorite() {
      await toggleFavourite({
        entityUrn: self.entityUrn,
        name: self.name
      });
      self.updateFavouriteFlag(!self.isFavourite);
    },

    updateFavouriteFlag(value) {
      self.isFavourite = value;
    },

    async createNewVersion(dUrn, approvingAVersionRequired, result) {
      const {
        data: { projectDetails: project }
      } = result;
      const {
        id,
        name,
        status,
        dateCreated,
        alfresco = {},
        alfrescoEpubDetail = {},
        publishInfo,
        authorName,
        origin,
        sourceenv,
        sourcedurn,
        sourcetitle
      } = project;

      const newVersion = {
        id,
        name,
        status,
        dateCreated,
        alfresco,
        alfrescoEpubDetail,
        publishInfo,
        authorName,
        origin,
        sourcetitle,
        sourcedurn,
        sourceenv
      };

      // update the status of the previous version to approved if it was wip(WIP_FAILED and WIP-DELIVERED scenerios)
      if (approvingAVersionRequired) {
        self.updatePreviousVersionStatusToApproved();
      }

      // Add new project version in versions list
      const versions = [newVersion, ...toJS(self.versions)];

      // increase version count by 1
      self.updateTotalVersionCount(self.totalVersionCount + 1);

      // update versions
      self.updateVersionList(versions);

      // Update project detail with previous favorite & permissions
      self.update({
        ...self,
        ...project
      });

      // Updating isProjectEdited flag to true
      self.updateIsProjectEdited(true);

      if (!self.isBlueprintIPMProjectAssociated) {
        self.setBlueprintInfo('', '', '');
      }

      return result;
    },

    updateProductID(productId) {
      self.productId = productId;

      // Updating isProjectEdited flag to true
      self.updateIsProjectEdited(true);
    },

    updateQuadFolder(quadFolder) {
      self.quadFolder = quadFolder;

      // Updating isProjectEdited flag to true
      self.updateIsProjectEdited(true);
    },

    // updtae the status of the previous version to approved if it was wip(WIP_FAILED and WIP-DELIVERED scenerios)
    updatePreviousVersionStatusToApproved() {
      const newStatus = {
        projectStatus: Constants.VERSION_APPROVED,
        publishStatus:
          self.versions[0].status && self.versions[0].status.publishStatus
      };
      self.versions[0].status = newStatus;
    },
    /* updating latest version status after promote, setting it to IN-PROGRESS forcefully after promote
   as version status takes times to update on backend and setting isLocked to true
   NOTE: this method will be called only after promote
   */
    updateLatestVersionStatusToInprogress(selectedproductModel) {
      // setting current version status and setting the user info to null
      const status = {
        projectStatus: Constants.VERSION_WIP,
        publishStatus: Constants.VERSION_STATUS_IN_PROGRESS
      };
      self.versions[0].status = status;
      self.versions[0].userApproverFirstName = null;

      // setting current version publishInfo
      const productModel =
        selectedproductModel === Constants.ETEXT2_BAU
          ? Constants.ETEXT2_BAU_LABEL
          : selectedproductModel;
      self.versions[0].publishInfo = {
        productModel
      };

      // setting isLocked
      self.isLocked = true;
      // setting publishStatus
      self.publishStatus = Constants.VERSION_STATUS_IN_PROGRESS;

      // Updating isProjectEdited flag to true
      self.updateIsProjectEdited(true);
    },

    updateProjectStatusByLatestVersion(latestVersion) {
      const {
        status: { projectStatus, publishStatus }
      } = latestVersion;
      self.status = projectStatus;
      self.publishStatus = publishStatus;
      const lock = self.shouldLockProject(
        projectStatus,
        publishStatus,
        self.autoNumberingTransactionId
      );
      self.lockProject(lock);
    },

    /**
     * Updating self.isProjectEdited flag, which will be
     * utilized to check whether the project details are edited
     * through UI or not.
     * @param {boolean} value
     */
    updateIsProjectEdited(value) {
      self.isProjectEdited = value;
    },

    shouldLockProject(
      projectStatus,
      publishStatus,
      autoNumberingTransactionId
    ) {
      if (projectStatus === Constants.VERSION_APPROVED) {
        return true;
      }
      if (publishStatus === Constants.VERSION_STATUS_IN_PROGRESS) {
        return true;
      }
      if (autoNumberingTransactionId) {
        return true;
      }
      return false;
    },

    lockProject(lock) {
      self.isLocked = lock;
    },

    async getAllVersions(entityUrn) {
      const { versions, totalVersionCount } = await getVersions(entityUrn);
      self.updateTotalVersionCount(totalVersionCount);
      // update versions
      self.updateVersionList(versions);
      self.updateProjectStatusByLatestVersion(versions[0]);
    },

    updateUserCount(value) {
      self.userCount = value;
    },

    updateInstructorResourceUrl(value) {
      self.instructorResourceUrl = value;
    },
    setGlossaryOption(value) {
      self.glossaryOption = value;
    },
    updateLineOfBusiness(value) {
      self.lineOfBusiness = value;
      // Updating isProjectEdited flag to true
      self.updateIsProjectEdited(true);
    },
    updateTaxonomy(type, value) {
      self[type] = value;
      // Updating isProjectEdited flag to true
      self.updateIsProjectEdited(true);
    },

    async getAssociatedTheme(dURN) {
      self.associatedTheme = [];
      const associatedThemeItem = await getAssociatedThemeList(dURN);
      if (Array.isArray(associatedThemeItem)) {
        self.updateTheme(associatedThemeItem[0]);
      }
    },
    updateTheme(themeItem) {
      if (themeItem) {
        const { name, themeUrn } = themeItem;
        self.associatedTheme = [{ name, themeUrn }];
      }
    },
    async updateOwnerRole() {
      await updateContentRole(self.id, Constants.OWNER);
      self.setOwnerRole();
    },
    setOwnerRole() {
      self.sharingContextRole = Constants.OWNER;
      self.updateIsProjectEdited(true);
    },
    async updateSubscription(subscription) {
      await updateContentRole(self.id, subscription);
      self.setSubscription(subscription);
    },
    setSubscription(subscription) {
      self.sharingContextRole = subscription;
      self.updateIsProjectEdited(true);
    },
    setIsCypressPlusEnabled(value) {
      self.isCypressPlusEnabled = value;
      self.updateIsProjectEdited(true);
    },
    setisH5PContentEnabled(value) {
      self.isH5PContentEnabled = value;
      self.updateIsProjectEdited(true);
      self.updatePublishingStatus([]);
    },
    setIsProjectCancelled(value) {
      self.isProjectCancelled = value;
      self.updateIsProjectEdited(true);
    },
    updateProjectNotes(notes) {
      self.projectNotes = notes;
      self.updateIsProjectEdited(true);
    },
    updateAutoNumberingTransactionId(id) {
      self.autoNumberingTransactionId = id;
    },
    setRevelAudioFlagOverride(audioFlag) {
      self.revelAudioFlagOverride = audioFlag;
      self.updateIsProjectEdited(true);
    },
    setDefaultLearningFramework(defaultLearningFramework) {
      self.defaultLearningFramework = defaultLearningFramework;
      self.updateIsProjectEdited(true);
    },
    setIsEbookProject(value) {
      self.isEbookProject = value;
    },
    setIsBlueprintIPMProjectAssociated(value) {
      self.isBlueprintIPMProjectAssociated = value;
    },
    setBlueprintInfo(ipmProjectNumber, blueprintProductId, productInfoUrn) {
      self.ipmProjectNumber = ipmProjectNumber;
      self.blueprintProductId = blueprintProductId;
      self.productInfoUrn = productInfoUrn;
    }
  }));
